<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Form from "./components/form.vue";
import { userMethods } from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader,
    Form,
  },
  page: {
      title: "Edit Manager",
      meta: [
          {
              name: "description",
              content: appConfig.description,
          },
      ],
  },
  data() {
    return {
      processing: false,
      title: "Edit Manager",
      items: [
        {
          text: "Create Manager",
        },
        {
          text: "Create Manager",
          active: true,
        },
      ],
      old_password: null,
      new_password: null,
      confirm_password: null,
    };
  },
  mounted() {
    this.fetchManager();
  },

  methods: {
    ...userMethods,
    fetchManager() {
      this.$axios
        .get("/employee/manager/" + this.$route.params.user_id)
        .then((response) => {
          let data = response.data.payload;
          data.locations = data.employee.locations;
          data.work_area_role = data.employee.area_roles;
          data.experience_level = data.employee.experience_level.split(/(\d+)/)[1];
          let newArray = [];
          data.employee.area_roles.forEach(( workarea_name)=>{ 
            let found =  newArray.findIndex(area => area.id == workarea_name.area.id);
            if(found  < 0){
              newArray.push(workarea_name.area);
              }
          });
          data.work_area = newArray;
          this.$store.state.userModule.user = data;
          this.$refs.EditManager.fetchLocations();
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },

    submitUser() {
      let user = this.$store.state.userModule.user;
      let self = this;
      self.$refs.EditManager.checkValidate();
      if(self.processing || this.$refs.EditManager.phoneError == false || self.$refs.EditManager.validateErorr())
      {
        return;
      }
      self.processing = true;
      self.$axios
        .post("/employee/manager/update/" + user.id, {
          name: user.name,
          email: user.email,
          phone: user.phone,
          avatar: user.avatar,
          password: user.password,
          experience_level : user.experience_level,
          location_id: user.locations.map((item) => {
            return item.id;
          }),
          work_area_role_id: user.work_area_role.map((item) => {
            return item.id;
          }),
          employee_id: user.employee.id,
        })
        .then((response) => {
          let data = response.data;

          self.triggerSwal(data.message, "success");
          self.resetState();
          self.$router.push("/managers-listing");
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },
    updatePassword(){
      let user = this.$store.state.userModule.user;
      let self = this;
      if(self.new_password!=self.confirm_password){
        self.triggerSwal('Confrim Password Not Match', "error");
        return;
      }
      if (self.processing) {
        return;
      }
      self.processing = true;

      self.$axios
        .post("/user/update-password/" + user.id, {
          id: user.id,
          new_password: self.new_password,
          old_password: self.old_password,
          
        })
        .then((response) => {
          let data = response.data;
          self.triggerSwal(data.message, "success");
        })
        .catch((error) => {
          self.handleErrorResponse(error.response, "error");
        })
        .finally(() => {
          self.processing = false;
        });
    },
  },

  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="card p-4 radius-20">
      <b-tabs pills class="tabs-pills">
        <b-tab title="Profile" active>
          <Form
            :passwordInput="true"
            ref="EditManager"
            @submitUserData="submitUser"
          ></Form>
        </b-tab>
        <b-tab title="Change Password" >
          <div class="mt-3">
            <div class="card-body">
              <form class="form-horizontal" role="form">
                <div class="row justify-content-md-center">
                  <div class="col-md-4">
                    <b-form-group
                      id="old_password-group"
                      label="Old Password"
                      class="mb-3"
                      label-for="old_password"
                    >
                      <b-form-input
                        id="old_password"
                        v-model="old_password"
                        type="password"
                        placeholder="Enter password"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group
                      id="password-group"
                      label="New Password"
                      class="mb-3"
                      label-for="password"
                    >
                      <b-form-input
                        id="password"
                        v-model="new_password"
                        type="password"
                        placeholder="Enter password"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-4">
                    <b-form-group
                      id="confirm_password-group"
                      label="Confirm Password"
                      class="mb-3"
                      label-for="confirm_password"
                    >
                      <b-form-input
                        id="confirm_password"
                        v-model="confirm_password"
                        type="password"
                        placeholder="Enter password"
                        required
                      >
                      </b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-12">
                    <SubmitButton :processing="processing" @clicked="updatePassword" text="Update Password"></SubmitButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </b-tab>
    </b-tabs>
    </div>
  </Layout>
</template>
